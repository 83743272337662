import styled from "styled-components";
import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionContainer, ContentContainer } from "components";


export default () => {

  const { i18n } = useTranslation()
  useEffect(() => window.scrollTo(0, 0), [])

  return (
    <SectionContainer>
      <StyledContentContainer>
        {i18n.language == "nl" ? dutch : english}
      </StyledContentContainer>
    </SectionContainer>
  )
}

const StyledContentContainer = styled(ContentContainer)`
  color: var(--text-color);
  text-align: left;
  line-height: 1.9;
  & strong {
    /* font-size: 18px; */
  }
`;

const english = (
  <>
    <p><strong>TERMS AND CONDITIONS</strong></p>
    <p>&zwj;<strong>March2022_EN</strong></p>
    <p>Of F5Scan B.V., with its registered office at Poortweg 4C, NL-2612 PA Delft, the Netherlands, listed in the Commercial Register of the Chamber of Commerce under number 58318836 (hereafter: the &lsquo;<strong>Supplier</strong>&rsquo;).</p>
    <p><strong></strong></p>
    <p><strong>Article 1.    Applicability of Terms and Conditions</strong></p>
    <p>1.1   These General Terms and Conditions apply to all quotes and proposals, offers, contracts, agreements and/or other legal relationships as part of which the Supplier provides products and/or services to the Client relating to the SaaS service, of any type and under any name whatsoever.</p>
    <p>1.2   Any departures from, and additions to, these General Terms and Conditions will be valid only if they have been agreed between the Supplier and the Client in writing.</p>
    <p>1.3   Unless expressly otherwise agreed between the Parties, the applicability of any terms of purchase or other terms and conditions of the Client is expressly excluded.</p>
    <p>1.4   If any provision of these General Terms and Conditions is null and void or has been nulled, the remaining provisions of these General Terms and Conditions will remain in full force and effect. In that case, the Supplier and the Client will consult each other with the objective of agreeing new provisions to replace the null and void or nulled provisions.</p>
    <p>1.5   In the event of a conflict between any provisions of these General Terms and Conditions and the terms contractually agreed between the Supplier and the Client, the provisions of the Agreement will prevail, unless otherwise specified.</p>
    <p></p>
    <p>Article 2.    <strong>Definitions</strong></p>
    <p>2.1   The capitalized terms contained in these General Terms and Conditions have the meanings assigned to them in this article:</p>
    <p><strong>Client:</strong>a natural person or legal entity who or that enters into an agreement with the Supplier.</p>
    <p><strong>Service:</strong>facilitation of the use of the Software as a Service and support;</p>
    <p><strong>Documentation:</strong> the user manual(s) for the Software, drafted or provided by the Supplier;</p>
    <p><strong>Users:</strong>employees, representatives, consultants, contractors or agents who are authorized to use the Service under the Client&rsquo;s supervision;</p>
    <p><strong>Active users per month:</strong> all Users indicated/activated as such within the Weaver domain of the Client in a (part of a) calendar month;</p>
    <p><strong>Bug</strong>: a demonstrable and reproducible flaw in the Software causing its functionality to deviate substantially from the description of the functionality contained in the Documentation;</p>
    <p><strong>Notice of Default:</strong> written summon in which a Party is given a reasonable amount of time (at least ten business days) to yet fulfil its obligations under this Agreement;</p>
    <p><strong>Server(s):</strong> one or more computer(s) managed on behalf of the Client, which is/are used to host the Software and/or store data;</p>
    <p><strong>Software:</strong> the Weaver platform, including the tools belonging to the Client and provided to the Client for use.</p>
    <p><br /></p>
    <p><strong></strong>Article 3.    <strong>Quotes and Offers</strong></p>
    <p>3.1    All quotes and offers are free of obligation, unless expressly stated otherwise in the offer in writing. The Client warrants the accuracy and completeness of the data provided by or on behalf of the Client to the Supplier on which the Supplier has based its offer.</p>
    <p>3.2    The Supplier is under no obligation whatsoever to provide the Client with tools or resources for detecting or correcting data entry errors made by the Client. The Parties agree that the content of the Client&rsquo;s order, registration, or instruction as received by the Supplier is correct. Data entry errors and other errors made in the submission of the order, registration, or instruction will be at the Client&rsquo;s risk and expense.</p>
    <p></p>
    <p>Article 4.    <strong>Price and Payment</strong></p>
    <p>4.1    All prices are exclusive of Dutch value-added tax(VAT) and other charges imposed or to be imposed by the government. All prices issued by the Supplier will be stated in euros, and the Client will make all payments in euros.</p>
    <p>4.2    The Client is required to pay the rates agreed for the Service. The licensing fees and, if applicable, any additional Support packages, are payable prior to the licensing period. Payments for extra Users and/or Extra spaces are payable at the end of a calendar month. Any other payments, including, but not limited to customization, are payable after the work in question has been completed, unless otherwise specified. </p>
    <p>4.3    Unless otherwise agreed between the Parties, any fees associated with work performed on behalf of the Client on-site will be charged at the (hourly) rates applied by the Supplier.</p>
    <p>4.4    If the Client, according to the agreement signed between the Parties, consists of multiple individuals (natural persons) and/or legal entities, each of these individuals or legal entities will be jointly and severally liable to the Supplier to fulfil the Agreement.</p>
    <p>4.5    If the Client is subject to a periodic payment obligation, the Supplier will be authorized, in accordance with the index or other criterion included in the Agreement, to adjust the applicable prices and rates in writing to the period specified in the Agreement. In addition, the Supplier will be authorized at any time to adjust the applicable prices and rates in writing, subject to at least three (3) months&rsquo; notice. If the Client, in the latter case, does not agree to the adjustment, the Client will be authorized to terminate the Agreement in writing within thirty (30) days following notification of the amendment of the Agreement effective from the date on which the new prices and/or rates would take effect.</p>
    <p>4.6    In the event of late payment, the Supplier will be entitled:</p>
    <p>(I)   without a notice of default or notification being required, to charge the statutory interest on the full amount payable from the date on which payment should have been made until the date until the date on which the payment due has been received by the Supplier;</p>
    <p>(II)  to turn over the account to a third party for collection after notice of default. The Client will reimburse all the expenses the Supplier and the third party are compelled to incur in order to collect the amount payable, including legal fees, court fees and out-of-court fees, the latter at a minimum of fifteen (15)per cent of the amount outstanding, unless this would be unacceptable (due to the amount outstanding) according to the standards of reasonableness and fairness. A notice of default may be sent electronically if the Client has opted for electronic payment or if payment was made following receipt of an electronic invoice; and</p>
    <p>(III)  to suspend the Service until the amount payable has been received in full.</p>
    <p></p>
    <p>Article 5.    <strong>Term and Termination of the Agreement </strong></p>
    <p>5.1    If and to the extent that the Agreement signed between the Parties is a long-term agreement, the Agreement has been entered into for the period agreed between the Parties; otherwise, a period of one (1) month applies.</p>
    <p>5.2    The Agreement will be tacitly renewed by a period of one (1) month each time, unless the Supplier or Client terminates the Agreement in time in writing (including by e-mail), subject to one (1) month notice. </p>
    <p>5.3    The Agreement can be terminated through rescission.The rescission must always be announced by registered letter. Both Parties will only be authorized to rescind the Agreement if the other Party has failed imputably in the fulfillment of its obligations under the Agreement and the defaulting Party, even after receiving a written Notice of Default from the injured Party specifying a reasonable period to remedy the failure, neglects to fulfil its obligations under the Agreement, and in the event that it has been established that the defaulting Party can or will no longer fulfil its obligations under the Agreement.</p>
    <p>5.4    If the Client has already received services in the performance of the Agreement at the time of the rescission, these services and the related payment obligations will not be a subject of cancellation, unless the Client proves that the Supplier is in default with regard to a fundamental part of these services. Any amounts which the Supplier invoiced prior to the rescission in connection with the services which it already provided or delivered as required in the performance of the Agreement, will remain due and payable with due observance of the provisions of the foregoing sentence and will be immediately due and payable at the time of the rescission.</p>
    <p>5.5    Except for the provisions contained elsewhere in the General Terms and Conditions, both Parties will be authorized to rescind the Agreement with immediate effect if one of the following circumstances occurs or is impending:</p>
    <p>a.    if a bankruptcy petition is filed on behalf of the other Party;</p>
    <p>b.    if the other Party is declared bankrupt;</p>
    <p>c.    if the other Party is granted a moratorium (temporary or otherwise);</p>
    <p>d.   if the other Party&rsquo;s business is terminated or is transferred to a third party in whole or in part;</p>
    <p>e.   if the other Party&rsquo;s business is wound up;</p>
    <p>f.    if the other Party suspends its current operations or a significant portion of the other Party&rsquo;s assets are seized, or if the other Party should otherwise be deemed to be no longer able to fulfil its obligations under the Agreement.</p>
    <p>5.6    Section 408 of Book 7 of the Netherlands Civil Code does not apply in this case. The Client is not authorized to terminate a temporary contract for services prior to its expiry.</p>
    <p>5.7    The Supplier will be authorized to rescind the Agreement if:</p>
    <p>a.    the Client has been found to have withheld essential information; or</p>
    <p>b.    The Supplier has a well-founded fear that the Client will not fulfil its obligations to the Supplier.</p>
    <p>The Supplier will not be liable to pay any compensation for damages in this case, unless otherwise provided for by law.The Client will remain liable to cover the expenses incurred and hours spent up to that time. The Supplier will be authorized to continue the Agreement instead of rescinding it and to invoice the additional work carried out.</p>
    <p>5.8    Any obligations which, by their nature, are designed to continue on termination and/or rescission of the Agreement will remain in place following this date.</p>
    <p><strong></strong></p>
    <p><strong>Article 6.    Confidentiality</strong></p>
    <p>6.1    If and to the extent that, in the performance of the Agreement, either Party gains access to confidential information relating to the other Party, the recipient Party will use this information solely for the performance of the Agreement, and will restrict access to such information to individuals who require such access for this purpose. This does not apply to the Supplier if and to the extent that the provision of the relevant data to a third party is necessary following a court ruling, a statutory regulation or for the proper performance of the Agreement by the Supplier. Data and information will be deemed to be confidential, in any case, if the Supplier or Client has designated it as such. The Parties will treat personal data confidentially at all times.</p>
    <p>6.2    &lsquo;Confidential information&rsquo; does not include information which was already public at the time it was accessed or was revealed subsequently, or which the recipient Party has received from a third party without a duty of confidentiality having been imposed or the third party being bound by such duty. &lsquo;Confidential information&rsquo; as related to the Supplier includes any and all information regarding the features, functionality and provision of the Service which has not been made public. &lsquo;Confidential information&rsquo; as related to the Client includes any and all information which the Supplier has received from the Client in order to provide the Service.</p>
    <p><strong></strong></p>
    <p><strong>Article 7.    Data Processing</strong></p>
    <p>7.1    If this is necessary for the performance of the Agreement, the Client will inform the Supplier in writing upon request regarding how the Client is fulfilling its obligations under the applicable personal data protection laws.</p>
    <p>7.2    If the use of the Service involves personal data processing within the meaning of the General Data Protection Regulation (GDPR), the Client will be fully responsible for this data. The Supplier will be designated as the data processor and the Client will be designated as the data controller. The rights and obligations of the Parties will be provided for in a separate data processing agreement.</p>
    <p>7.3    The Client and the Users will determine what information and data is to be stored and/or exchanged through the use of the Service, without the Supplier being privy to this information. The Client assures the Supplier that the content, use and/or processing of the data are not unlawful and do not infringe on any third-party rights. The Client indemnifies the Supplier against any and all third-party legal claims, on any basis whatsoever, in connection with this data or the performance of the Agreement.</p>
    <p>7.4    The Client indemnifies the Supplier against any and all claims brought by individuals whose personal data has been recorded or processed or is held by the Client as part of data processing or for which the Client is responsible, unless the Client proves that the facts underlying the claim are attributable to the Supplier.</p>
    <p></p>
    <p>Article 8.    <strong>Security</strong></p>
    <p>8.1    If the Supplier is required, pursuant to the Agreement, to provide for a form of information security, such security will meet the specifications regarding security agreed between the Parties in writing. The Supplier cannot warrant that the information security will be effective under all conditions. If a security measure specified in the Agreement is not available or not provided, the security measures will satisfy a level which, having regard to the state of the art, the sensitivity of the data and the costs associated with the security, shall not be unreasonable.</p>
    <p>8.2    The Client will keep the access codes and identification codes provided confidential and may only be share these with authorized employees from its own organization or with authorized suppliers of the Client. The Supplier will be authorized to modify any access codes or identification codes and certificates it has been assigned.</p>
    <p>8.3    The Supplier will not be liable for any misuse of access codes or identification codes and can assume that a user who utilizes these access codes or identification codes of an active user is actually the active user in question. As soon as the Client is aware, or has a reason to assume, that unauthorized individuals have gained access to identification codes or access codes, the Client will immediately inform the Supplier of this in writing or by telephone, notwithstanding the Client&rsquo;s own obligation to immediately take effective measures.</p>
    <p>8.4    The Client is responsible for acquiring and maintaining hardware and any ancillary services which are necessary for accessing, connecting to and using the Service. The Client is also responsible for maintaining the security of the hardware.</p>
    <p>8.5    The Supplier will not be liable in any manner for any incompetent use of the Service by the Client or an End User. If a certain type of conduct on the part of the Client or its Users jeopardizes the operation of the Software, the Supplier will be authorized to temporarily or permanently deny or prevent access to the Software by an End User, following an initial written warning, including a written reminder.</p>
    <p></p>
    <p><strong>Article 9.    Intellectual Property Rights</strong></p>
    <p>9.1    The intellectual property rights pursuant to the Agreement or the Service provided to the Client will remain vested in the Supplier or in the third party from whom or from which the Supplier acquired the right to make this Software (or part thereof) available to the Client.</p>
    <p>9.2    The (intellectual) property rights to the data entered by the Supplier are vested in the Client or in the rights&apos; holder(s) of this data, including the data which is based on or derived from the Client&rsquo;s data and which were provided to the Client for the provision of the SaaS service.</p>
    <p>9.3    If the Supplier believes it is likely that it will be established that the Software provided by the Supplier infringes on any third-party rights, the Supplier will be authorized, at its discretion, (I) to ensure that the Client will be able to continue using the Software provided, or(II) to suspend the provision of the relevant SaaS service on payment of a reasonable fee for the use thereof and possible repayment of the excess amount, or (III) to provide alternative Software which, in the Supplier&rsquo;s reasonable opinion, is equivalent to the Software being replaced. Any further liability, obligation for further fulfillment or obligation to pay compensation is hereby excluded. The Client will inform the Supplier immediately of the existence and nature of such claims. The Client undertakes to provide the Supplier with any and all information which may be reasonably necessary, and to cooperate with the Supplier as required. If and to the extent that the alleged infringement was caused by changes in the Service provided which have been implemented by the Client or by a third party on behalf of the Client, by the use of Software in conjunction with Software not provided by the Supplier or as a result of use for a purpose or in a manner other than for which the Service was developed or designed, the Supplier will hold the Client liable for the implications thereof.</p>
    <p>9.4    The Client warrants that it, if and to the extent that it has been provided with materials or data under the Agreement, it is authorized to do so and that these materials and data do not infringe on any third-party rights. The Client indemnifies the Supplier against any third-party claims based on the claim that such provision, use, maintenance, editing, installation or integration infringes on any rights of such third-party.</p>
    <p>9.5    The Client will not be authorized to remove or alter any copyright specifications, trademarks or other indications of the rights holder to intellectual property rights from the Software supplied. The above also applies to announcements stating that specific information is of a confidential nature.</p>
    <p></p>
    <p>Article 10.  <strong>Parties&rsquo; Obligations</strong></p>
    <p>10.1  In order to facilitate the proper performance of the Agreement by the Supplier, the Client will always provide the Supplier with the data or information reasonably requested by the Supplier and will do so in a timely manner. The Supplier will be required to perform the Agreement to the best of its knowledge and ability.</p>
    <p>10.2  If the Supplier is confronted, in the performance with the Agreement and/or the provision of the Service, with any facts or circumstances which could potentially hinder the progress of its work, the Supplier will notify the Client as soon as possible.</p>
    <p>10.3  The Client will provide the Supplier in a timely manner with all data which the Supplier requires for the proper performance of the Agreement and will cooperate as necessary.</p>
    <p>10.4  If the data and information required for the performance of the Agreement or the provision of the Service are not available to the Supplier, or are provided too late or not in accordance with the agreements or if the Client fails to satisfy its obligations in another manner, the Supplier will be entitled to suspend the performance of the Agreement or the provision of the Service and to charge the costs arising as a result, applying its standard rates.</p>
    <p>10.5  The Client is responsible for the use of the Service and the manner in which the results of the Service are used. The Client is responsible for providing instructions to, and the use by, Users. The Client warrants that the Users possess the requisite knowledge and experience to use the Service.</p>
    <p>10.6  The Supplier will notify the Client without delay if any circumstances occur which result in an amendment of the engagement and/or the estimated commitment and duration. The Supplier will be authorized to send additional invoices if such circumstances were to occur and will report this to the Client before taking the appropriate action. The Client will be deemed to have agreed to the performance of the additional work and the related costs and charges if the Client has allowed the additional work to be performed without confirmation in writing and within a reasonable period of time that it does not wish for the work to be carried out.</p>
    <p><strong></strong></p>
    <p>Article 11.  <strong>Service</strong></p>
    <p>11.1  The Supplier will provide the Service to the Client during the term of the Agreement. On commencement of the Agreement and on request, the Supplier will provide account details for the purpose of using the Service.</p>
    <p>11.2  The Client is authorized to permit Users within its organization to use the Services for the organization&rsquo;s internal purposes.</p>
    <p>11.3  Unless expressly otherwise agreed between the Parties, the Supplier will not be required to perform additional work. If the Parties have agreed (other) additional work, this will be subject to the terms expressly agreed by the Parties in writing.</p>
    <p></p>
    <p><strong>Article 12.   Right of Use/License</strong></p>
    <p>12.1   The use of the Service includes the non-exclusive right to the regular use of the Software, without any entitlement to provision of a copy of the Software in any form or format whatsoever.</p>
    <p>12.2   The right of use (license) to the Software is not transferable to third parties (third parties also include the Client&rsquo;s parent and sister companies and/or subsidiaries).</p>
    <p>12.3   This right is restricted to the exclusive use of the Software for personal use. The Client is not authorized to use the Software for commercial purposes, including: distribution, sale, lease, representation, provision on a commercial or unpaid basis or any other form of trade/commerce.</p>
    <p>12.4   If no other arrangements have been made, the right of use is limited to one (1) user.</p>
    <p>12.5   The right of use will take effect after the Client has made payment and once the Client has fulfilled its other obligations.</p>
    <p>12.6  The right of use is restricted to access to, and use of, the Software. Rights to the source code will not be provided.</p>
    <p>12.7   The Supplier facilitates the use of the Service through its own hosting service.</p>
    <p>12.8  Reverse engineering, de-compilation or any other form of change, modification or alteration of the Software provided into a human-readable format by the Client is prohibited, unless explicitly permitted under mandatory law or under the Agreement. In addition, it is not permitted to remove security features or circumvent technical restrictions (i.e. restrictions of use) in relation to the Software.</p>
    <p></p>
    <p><strong>Article 13.   Warranty and Maintenance</strong></p>
    <p>13.1  The Supplier will endeavor to keep the Service available to the Client without interruption, where &lsquo;availability&rsquo; is understood to mean that the Service is actually provided on the Server.&lsquo;Availability&rsquo; specifically does not include the existence of an operational point-to-point connection between the Client&rsquo;s systems and the Server, as the Client cannot exercise any control over the Client&rsquo;s systems and the intermediate internet infrastructure. </p>
    <p>13.2  The Software which is the subject of the Service is a generic product. The Supplier does not guarantee that the Software provided under the Agreement is error-free, is designed for the intended use, or will otherwise operate without interruption at all times.</p>
    <p>13.3  The Supplier will endeavor to remedy any Bugs in the Software within a reasonable period of time if and to the extent this concerns the Software developed by the Supplier in-house and the Client has described the errors in question in detail in writing. The Client can identify the Bug and this Bug can be reproduced. The Client will cooperate in any reasonable manner required when restoring the services.</p>
    <p>13.4  In the event that the Bug in question does not relate to the Service or the Software but rather relates to (I) incompetent use, (II)user errors or (III) external causes, the Supplier will charge previously incurred expenses relating to the (incident) investigation and maintenance in accordance with the prices and rates applied by the Supplier.</p>
    <p>13.5  The Supplier reserves the right to temporarily deactivate the Service for the purpose of maintenance of, and updates and upgrades to, the Supplier&rsquo;s computer systems. The Supplier will ensure that this deactivation takes place outside office hours to the extent possible, and will inform the Client of the scheduled deactivation in a timely manner. The Supplier will never be liable to pay the Client any compensation whatsoever due to deactivation, as meant here.</p>
    <p>13.6  The Supplier will be entitled to modify the Software intermittently without prior consent in order to improve its functionality, remedy Bugs and address security risks. The Supplier will endeavor to remedy any Bugs in the Software, but cannot guarantee that all Bugs will indeed be remedied. If a modification results in a change in functionality, the Supplier will inform the Client of this prior to the modification. As the Service is provided to multiple clients, it is not possible to cancel a specific application exclusively for the Client.</p>
    <p>13.7  This article does not apply to Software which the Supplier purchases from third parties. With regard to the use, the availability and maintenance of third-party Software, the Supplier can never be held accountable for more than agreed between the Supplier and its vendor.</p>
    <p>13.8  The Supplier will back up the Client&rsquo;s data at least once per business day. </p>
    <p>13.9  If the Client wishes to purchase additional services, the Supplier will provide an estimate for the provision of these services for approval prior to the planning and performance of the work. Unless otherwise agreed upon in writing, the work will be charged based on actual cost, including a clear breakdown of the various items.</p>
    <p>13.10 The development or provision of customized solutions in addition to or derivation of the (standard) Software (&lsquo;client-specific version&rsquo;) is possible based on a proposal submitted by the Supplier. The maintenance of customized software is excluded from regular maintenance and can also be offered by the Supplier as additional work based on a proposal submitted by the Supplier.</p>
    <p></p>
    <p><strong>Article 14.  Support</strong></p>
    <p>14.1  The Supplier will provide assistance in the form of support in the use of the Service (and answer technical questions relating thereto), in the manner agreed with the Client in the Agreement. Support will be provided exclusively through the contact(s) designated by the Client in advance. While the Supplier will endeavor to answer the questions as adequately as possible, it cannot warrant the accuracy and/or completeness of the answers.</p>
    <p>14.2  The Client can report any flaws or errors (&lsquo;Bugs&rsquo;) in the Service in the manner specified in the Agreement. Reporting and resolving/fixing Bugs does not form part of the support services, but rather is included under maintenance, and the related costs will not be applied to the support to which the Client is entitled. </p>
    <p>14.3  The use and scope of support provided to the Client is bound by the limitations as agreed with the Client in the Agreement or in absence thereof, limited to a maximum of 8 (eight) hours of support a month.</p>
    <p></p>
    <p>Article 15.  <strong>Liability</strong></p>
    <p>15.1  If a Party fails to fulfil one or more of its obligations under the Agreement, the other Party will provide it with a notice of default in writing and in as detailed a manner as possible, whereby a reasonable period is set for the repair of the flaw, with the exception of the provisions of subsection 4 of this article. The notice of default must contain as complete and detailed a description as possible, in order that the relevant party is provided with the opportunity to respond as required.</p>
    <p>15.2  The liability of the Supplier for any losses incurred by the Client, irrespective of its basis, is limited to compensation of direct loss or damage up to a maximum of the amount of the price stipulated in the Agreement (exclusive of Dutch VAT). If the Agreement has a term of more than one year, the price stipulated for the Agreement is set at the total amount of the fees and charges (exclusive of Dutch VAT) stipulated for one year. A condition for any right to compensation is that the Client must report the loss or damage identified to the Supplier in writing as soon as possible after it has arisen. Any claim(s) for compensation against the Supplier will no longer be valid once twenty-four (24) months have elapsed since the claim arose, unless the Client brought a legal claim for compensation of the loss or damage prior to the expiry of this 24-month period.</p>
    <p>15.3  &lsquo;Direct loss&rsquo; is defined exclusively as:</p>
    <p>(I)   the expenses incurred by the Client for being compelled to keep its old system or systems and the related facilities operational longer than necessary due to the Supplier&rsquo;s failure to provide services by a legally binding date, excluding any savings resulting from the delayed services;</p>
    <p>(II)   reasonable expenses, incurred in identifying the cause and scope of the loss or damage, to the extent that such identification relates to direct loss or damage within the meaning of this article;</p>
    <p>(III)  reasonable expenses, incurred to avoid or mitigate loss, to the extent that the Client can demonstrate that these expenses have resulted in a limitation of direct loss or damage within the meaning of this article.</p>
    <p>15.4  The Supplier cannot be held liable for any indirect or consequential loss or damage. Examples of such loss or damage include loss of profit, lost savings, loss of goodwill, the poor performance of third-party materials or software or business interruption, even if the Supplier is aware(of the possibility) that loss can or will arise. The Supplier will never be obliged to restore damaged or lost data.</p>
    <p>15.5  With due observance of the provisions for intellectual property rights, the Client indemnifies the Supplier against any third-party claims on any basis whatsoever in relation to the compensation of loss, expenses or interest relating to the Agreement.</p>
    <p>15.6  The exclusions and limitations of the Supplier&rsquo;s liability specified in subsections 2 to 5 of this article will be cancelled if and to the extent that the relevant loss or damage is caused by willful misconduct or deliberate recklessness on the part of the Supplier or its employees.</p>
    <p></p>
    <p><strong>Article 16.   Force Majeure</strong></p>
    <p>16.1  A Party will not be obliged to fulfil one or more obligations if it is prevented from doing so as a result of force majeure.</p>
    <p>16.2  &lsquo;Force majeure&rsquo; is defined in these General Terms and Conditions &ndash; in addition to the relevant provisions under the law &ndash; as any and all external causes, anticipated or unforeseen, over which the Supplier cannot exercise any control, thereby preventing it from fulfilling its obligations.This includes strike and industrial action undertaken by third parties. &lsquo;Force majeure&rsquo; is also understood to include imputable failure by any third parties or suppliers whose services have been engaged, not including financial obligations. The Supplier is also entitled to invoke force majeure if the circumstance preventing fulfillment or further fulfillment of the Agreement occurs after the Supplier should have honored its commitment.</p>
    <p>16.3  If a Party is affected by a force majeure event, the Party in question will report this to the other Party as soon as possible. The Parties will then consult with one another in order to reach a reasonable solution.</p>
    <p>16.4  As soon as it becomes apparent that the force majeure event will take more than sixty (60) days, either Party will be entitled to rescind the Agreement in writing without being liable to pay any compensation whatsoever. Any services provided prior to this time will then be settled in proportion to the work completed.</p>
    <p></p>
    <p><strong>Article 17.   Transfer</strong></p>
    <p>17.1  The Supplier will be authorized to transfer the rights and obligations under the Agreement to third parties and will notify the Client accordingly. If the transfer of obligations to a third party is reasonably unacceptable to the Client, it will be entitled to terminate the Agreement within five (5) days of receiving the notification to this effect.</p>
    <p>17.2  The Client will only be authorized to transfer its rights and obligations under the Agreement to a third party with the Supplier&rsquo;s prior written consent.</p>
    <p><strong></strong></p>
    <p><strong>Article 18.   Other Provisions</strong></p>
    <p>18.1  The Supplier is permitted to engage third parties in the performance of the Agreement. The Client is authorized to object to this and/or request additional information from the Supplier.</p>
    <p>18.2  Any captions and headings contained in these General Terms and Conditions serve only for clarification purposes and are not intended as an explanation or interpretation of the relevant provisions.</p>
    <p></p>
    <p>Article 19.  <strong>Applicable Law and Dispute Resolution</strong></p>
    <p>19.1  These General Terms and Conditions are subject to Dutch law.</p>
    <p>19.2  Any disputes which might arise in response to these General Terms and Conditions or any further agreements or contracts resulting therefrom will be settled exclusively by the competent court in Rotterdam, the Netherlands.</p>
  </>
)

const dutch = (
  <>
    <p><strong>VOORWAARDEN</strong></p>
    <p><strong>&zwj;</strong><strong>Maart2022_NL</strong></p>
    <p>Van F5Scan BV, statutair gevestigd aan de Poortweg 4C, NL-2612 PA Delft, Nederland, ingeschreven in het Handelsregister van de Kamer van Koophandel onder nummer 58318836 (hierna: de &lsquo;Leverancier&rsquo;).</p>
    <p><strong></strong></p>
    <p><strong>Artikel 1. Toepasselijkheid Algemene Voorwaarden</strong></p>
    <p>1.1 Deze Algemene Voorwaarden zijn van toepassing op alle offertes, aanbiedingen, overeenkomsten, overeenkomsten en/of andere rechtsbetrekkingen waarbij Leverancier producten en/of diensten aan Klant levert met betrekking tot de SaaS-dienst, van welke aard dan ook en onder welke naam dan ook.</p>
    <p>1.2 Eventuele afwijkingen van en aanvullingen op deze Algemene Voorwaarden zijn slechts geldig indien deze schriftelijk tussen Leverancier en Klant zijn overeengekomen.</p>
    <p>1.3 Tenzij tussen Partijen uitdrukkelijk anders is overeengekomen, wordt de toepasselijkheid van eventuele inkoop- of andere voorwaarden van Klant uitdrukkelijk uitgesloten.</p>
    <p>1.4 Indien enige bepaling van deze Algemene Voorwaarden nietig is of vernietigd wordt, blijven de overige bepalingen van deze Algemene Voorwaarden volledig van kracht.In dat geval zullen Leverancier en Klant in overleg treden met als doel nieuwe bepalingen ter vervanging van de nietige c.q. vernietigde bepalingen overeen te komen.</p>
    <p>1.5 In geval van strijdigheid tussen enige bepaling uit deze Algemene Voorwaarden en de voorwaarden die contractueel tussen Leverancier en Klant zijn overeengekomen, prevaleren de bepalingen uit de Overeenkomst, tenzij anders aangegeven.</p>
    <p></p>
    <p>Artikel 2.    <strong>Definities</strong></p>
    <p>2.1 De in deze Algemene Voorwaarden met een hoofdletter geschreven termen hebben de betekenis die daaraan in dit artikel wordt toegekend:</p>
    <p><strong>Klant:</strong> een natuurlijk persoon of rechtspersoon die een overeenkomst aangaat met Leverancier.</p>
    <p><strong>Dienst:</strong> het faciliteren van het gebruik van de Software as a Service en ondersteuning;</p>
    <p><strong>Documentatie:</strong> de gebruikershandleiding(en) voor de Programmatuur, opgesteld of verstrekt door Leverancier;</p>
    <p><strong>Gebruikers:</strong> werknemers, vertegenwoordigers, adviseurs, opdrachtnemers of agenten die bevoegd zijn om onder toezicht van Klant gebruik te maken van de Dienst;</p>
    <p><strong>Actieve gebruikers per maand:</strong> alle als zodanig binnen het Weaver-domein van Klant aangegeven/geactiveerde Gebruikers in een (gedeelte van een) kalendermaand;</p>
    <p><strong>Bug</strong> : een aantoonbare en reproduceerbare fout in de Programmatuur waardoor de functionaliteit ervan substantieel afwijkt van de beschrijving van de functionaliteit vervat in de Documentatie;</p>
    <p><strong>Ingebrekestelling:</strong> schriftelijke sommatie waarin een Partij een redelijke termijn (minimaal tien werkdagen) wordt gegund om alsnog aan haar verplichtingen uit deze Overeenkomst te voldoen;</p>
    <p><strong>Server(s):</strong> &eacute;&eacute;n of meer namens Klant beheerde computer(s) die wordt/worden gebruikt voor het hosten van de Software en/of het opslaan van gegevens;</p>
    <p><strong>Software:</strong> het Weaver-platform, inclusief de tools die eigendom zijn van Klant en die aan Klant ter gebruik worden verstrekt.</p>
    <p><br /></p>
    <p><strong></strong>Artikel 3.    <strong>Offertes en aanbiedingen</strong></p>
    <p>3.1 Alle offertes en aanbiedingen zijn vrijblijvend, tenzij in de aanbieding uitdrukkelijk schriftelijk anders is vermeld.Klant staat in voor de juistheid en volledigheid van de door of namens Klant aan Leverancier verstrekte gegevens waarop Leverancier zijn aanbieding heeft gebaseerd.</p>
    <p>3.2 Leverancier heeft geen enkele verplichting om Cli&euml;nt hulpmiddelen of hulpmiddelen ter beschikking te stellen voor het opsporen of corrigeren van door Cli&euml;nt gemaakte gegevens invoer fouten.Partijen komen overeen dat de inhoud van de bestelling, inschrijving of instructie van Klant zoals ontvangen door Leverancier juist is.Gegevens invoer fouten en andere fouten bij het doorgeven van de bestelling, registratie of instructie komen voor rekening en risico van de Klant.</p>
    <p></p>
    <p>Artikel 4.    <strong>Prijs en betaling</strong></p>
    <p>4.1 Alle prijzen zijn exclusief omzetbelasting (BTW) en andere heffingen die van overheidswege worden of zullen worden opgelegd.Alle door Leverancier afgegeven prijzen worden vermeld in euro&apos;s, terwijl Klant alle betalingen in euro&apos;s zal verrichten.</p>
    <p>4.2 Klant is verplicht de voor de Dienst overeengekomen tarieven te betalen. De licentiekosten en, indien van toepassing, eventuele aanvullende ondersteuningspakketten, zijn betaalbaar voorafgaand aan de licentieperiode. Betalingen voor extra Gebruikers en/of Extra ruimtes dienen per einde van een kalendermaand te worden voldaan. Eventuele overige betalingen, waaronder maar niet beperkt tot maatwerk, zijn betaalbaar nadat de betreffende werkzaamheden zijn voltooid, tenzij anders aangegeven. </p>
    <p>4.3 Tenzij tussen Partijen anders is overeengekomen, worden de eventuele vergoedingen die verband houden met werkzaamheden die ten behoeve van Klant op locatie worden verricht, in rekening gebracht tegen de door Leverancier gehanteerde (uur)tarieven.</p>
    <p>4.4 Indien Klant blijkens de tussen Partijen gesloten overeenkomst bestaat uit meerdere natuurlijke personen en/of rechtspersonen, is ieder van deze natuurlijke personen of rechtspersonen jegens Leverancier hoofdelijk aansprakelijk voor de nakoming van de Overeenkomst.</p>
    <p>4.5 Indien op Cli&euml;nt een periodieke betalingsverplichting rust, is Leverancier bevoegd conform het in de Overeenkomst opgenomen index- of ander criterium de geldende prijzen en tarieven schriftelijk aan te passen aan de in de Overeenkomst genoemde termijn.Daarnaast is Leverancier te allen tijde bevoegd de geldende prijzen en tarieven schriftelijk aan te passen, met inachtneming van een opzegtermijn van minimaal drie (3) maanden.Indien Klant in dit laatste geval niet akkoord gaat met de aanpassing, heeft Klant het recht om binnen dertig (30) dagen na kennisgeving van de wijziging van de Overeenkomst de Overeenkomst schriftelijk te be&euml;indigen met ingang van de datum waarop de nieuwe prijzen ingaan. En/of tarieven van kracht zouden worden.</p>
    <p>4.6 Bij niet tijdige betaling heeft Leverancier het recht:</p>
    <p>(I) zonder dat een ingebrekestelling of mededeling nodig is, over het volledig opeisbare bedrag de wettelijke rente in rekening te brengen vanaf de datum waarop betaling had moeten plaatsvinden tot aan de datum waarop de verschuldigde betaling door Leverancier is ontvangen;</p>
    <p>(II) na ingebrekestelling de rekening ter incasso aan een derde over te dragen.De Klant vergoedt alle kosten die Leverancier en de derde moeten maken om het verschuldigde bedrag te incasseren, inclusief juridische kosten, gerechtskosten en buitengerechtelijke kosten, deze laatste met een minimum van vijftien (15) per cent van het openstaande bedrag, tenzij dit naar maatstaven van redelijkheid en billijkheid onaanvaardbaar zou zijn (vanwege het openstaande bedrag).Een ingebrekestelling kan elektronisch worden verzonden indien de Klant heeft gekozen voor elektronische betaling of indien betaling heeft plaatsgevonden na ontvangst van een elektronische factuur;En</p>
    <p>(III) de Dienst op te schorten totdat het verschuldigde bedrag volledig is ontvangen.</p>
    <p></p>
    <p>Artikel 5.    <strong>Duur en be&euml;indiging van de Overeenkomst </strong></p>
    <p>5.1 Indien en voor zover de tussen Partijen gesloten Overeenkomst een overeenkomst van langere duur is, is de Overeenkomst aangegaan voor de tussen Partijen overeengekomen periode;anders geldt een termijn van &eacute;&eacute;n (1) maand.</p>
    <p>5.2 De Overeenkomst wordt telkens stilzwijgend verlengd met een periode van &eacute;&eacute;n (1) maand, tenzij Leverancier of Klant de Overeenkomst tijdig schriftelijk (waaronder per e-mail) opzegt, met inachtneming van een opzegtermijn van &eacute;&eacute;n (1) maand. </p>
    <p>5.3 De Overeenkomst kan worden be&euml;indigd door ontbinding. De ontbinding dient altijd per aangetekende brief kenbaar te worden gemaakt.Beide Partijen zijn slechts bevoegd de Overeenkomst te ontbinden indien de andere Partij toerekenbaar is tekortgeschoten in de nakoming van haar verplichtingen uit de Overeenkomst en de in gebreke zijnde Partij, zelfs na ontvangst van een schriftelijke ingebrekestelling van de benadeelde Partij waarin een redelijke termijn wordt gesteld om de tekortkoming te herstellen. tekortschiet, zijn verplichtingen uit de Overeenkomst niet nakomt, en indien vaststaat dat de in gebreke zijnde Partij niet meer aan zijn verplichtingen uit de Overeenkomst kan of wil voldoen.</p>
    <p>5.4 Indien Klant op het moment van de ontbinding reeds diensten heeft ontvangen ter uitvoering van de Overeenkomst, zullen deze diensten en de daarmee samenhangende betalingsverplichtingen geen voorwerp van ongedaan making zijn, tenzij Klant bewijst dat Leverancier ter zake van een fout in verzuim is. Fundamenteel onderdeel van deze diensten.Eventuele bedragen die Leverancier v&oacute;&oacute;r de ontbinding heeft gefactureerd in verband met de diensten die hij reeds heeft verleend of geleverd zoals vereist bij de uitvoering van de Overeenkomst, blijven met inachtneming van het bepaalde in de vorige zin opeisbaar en worden onmiddellijk opeisbaar. En betaalbaar op het moment van de ontbinding.</p>
    <p>5.5 Behoudens het elders in de Algemene Voorwaarden bepaalde zijn beide Partijen bevoegd de Overeenkomst met onmiddellijke ingang te ontbinden indien &eacute;&eacute;n van de volgende omstandigheden zich voordoet of dreigt:</p>
    <p>A.indien namens de andere Partij een faillissementsaanvraag wordt ingediend;</p>
    <p>B.indien de andere Partij failliet wordt verklaard;</p>
    <p>C.indien aan de andere Partij (al dan niet tijdelijke) surs&eacute;ance wordt verleend;</p>
    <p>D.indien de onderneming van de andere Partij geheel of gedeeltelijk wordt be&euml;indigd of overgedragen aan een derde;</p>
    <p>e.indien de onderneming van de andere Partij wordt geliquideerd;</p>
    <p>F.indien de andere Partij haar lopende activiteiten staakt of beslag wordt gelegd op een aanzienlijk deel van de activa van de andere Partij, of indien de andere Partij anderszins geacht wordt niet langer in staat te zijn haar verplichtingen uit hoofde van de Overeenkomst na te komen.</p>
    <p>5.6 Artikel 408 Boek 7 van het Burgerlijk Wetboek is in dit geval niet van toepassing.Het is de Klant niet toegestaan een tijdelijke overeenkomst van opdracht v&oacute;&oacute;r het einde ervan op te zeggen.</p>
    <p>5.7 Leverancier is bevoegd de Overeenkomst te ontbinden, indien:</p>
    <p>A.de Klant blijkt essenti&euml;le informatie te hebben achtergehouden;of</p>
    <p>B.Leverancier heeft gegronde vrees dat Klant zijn verplichtingen jegens Leverancier niet zal nakomen.</p>
    <p>Leverancier is in dat geval niet gehouden tot enige schadevergoeding, tenzij de wet anders bepaalt. Cli&euml;nt blijft aansprakelijk voor de tot dan toe gemaakte kosten en bestede uren.Leverancier is bevoegd de Overeenkomst voort te zetten in plaats van te ontbinden en het verrichte meerwerk te factureren.</p>
    <p>5.8 Eventuele verplichtingen die naar hun aard bedoeld zijn om bij be&euml;indiging en/of ontbinding van de Overeenkomst voort te duren, blijven ook na deze datum van kracht.</p>
    <p><strong></strong></p>
    <p><strong>Artikel 6. Vertrouwelijkheid</strong></p>
    <p>6.1 Indien en voor zover een Partij bij de uitvoering van de Overeenkomst toegang krijgt tot vertrouwelijke informatie met betrekking tot de andere Partij, zal de ontvangende Partij deze informatie uitsluitend gebruiken voor de uitvoering van de Overeenkomst en de toegang daartoe beperken. Aan personen die voor dit doel dergelijke toegang nodig hebben.Dit geldt voor Leverancier niet indien en voor zover het verstrekken van de betreffende gegevens aan een derde noodzakelijk is naar aanleiding van een rechterlijke uitspraak, een wettelijk voorschrift of voor een goede uitvoering van de Overeenkomst door Leverancier.Gegevens en informatie worden in ieder geval als vertrouwelijk beschouwd indien leverancier of opdrachtgever deze als zodanig heeft aangemerkt.Partijen zullen persoonsgegevens te allen tijde vertrouwelijk behandelen.</p>
    <p>6.2 Onder &lsquo;Vertrouwelijke informatie&rsquo; wordt niet verstaan informatie die al openbaar was op het moment dat deze werd geraadpleegd of naderhand werd onthuld, of die de ontvangende Partij van een derde heeft ontvangen zonder dat daaraan een geheimhoudingsplicht is opgelegd of dat de derde daaraan gebonden is. Zo&rsquo;n plicht.&apos;Vertrouwelijke informatie&apos; met betrekking tot de Leverancier omvat alle informatie met betrekking tot de kenmerken, functionaliteit en levering van de Dienst die niet openbaar is gemaakt.Onder &apos;vertrouwelijke informatie&apos; met betrekking tot de Klant wordt verstaan alle informatie die Leverancier van de Klant heeft ontvangen om de Dienst te verlenen.</p>
    <p><strong></strong></p>
    <p><strong>Artikel 7. Gegevensverwerking</strong></p>
    <p>7.1 Indien dit voor de uitvoering van de Overeenkomst noodzakelijk is, zal Klant de Leverancier desgevraagd schriftelijk informeren over de wijze waarop Klant aan zijn verplichtingen op grond van de toepasselijke wetgeving inzake de bescherming van persoonsgegevens voldoet.</p>
    <p>7.2 Indien bij het gebruik van de Dienst sprake is van een verwerking van persoonsgegevens in de zin van de Algemene Verordening Gegevensbescherming (AVG), is Klant volledig verantwoordelijk voor deze gegevens.De Leverancier wordt aangewezen als gegevensverwerker en de Klant wordt aangewezen als de gegevensbeheerder.De rechten en plichten van Partijen zullen worden vastgelegd in een aparte verwerkersovereenkomst.</p>
    <p>7.3 Klant en Gebruikers bepalen welke informatie en gegevens met het gebruik van de Dienst worden opgeslagen en/of uitgewisseld, zonder dat Leverancier van deze informatie op de hoogte is.Klant garandeert Leverancier dat de inhoud, het gebruik en/of de verwerking van de gegevens niet onrechtmatig zijn en geen inbreuk maken op rechten van derden.Klant vrijwaart Leverancier voor alle rechtsaanspraken van derden, uit welke hoofde dan ook, in verband met deze gegevens of de uitvoering van de Overeenkomst.</p>
    <p>7.4 Cli&euml;nt vrijwaart Leverancier voor alle aanspraken van personen van wie persoonsgegevens door Cli&euml;nt zijn vastgelegd of verwerkt, dan wel door Cli&euml;nt worden bewaard in het kader van de gegevensverwerking of waarvoor Cli&euml;nt verantwoordelijk is, tenzij Cli&euml;nt bewijst dat de feiten die ten grondslag liggen aan de vorderingen zijn toerekenbaar aan de Leverancier.</p>
    <p></p>
    <p>Artikel 8.    <strong>Beveiliging</strong></p>
    <p>8.1 Indien Leverancier op grond van de Overeenkomst verplicht is te zorgen voor een vorm van informatiebeveiliging, zal deze beveiliging voldoen aan de specificaties ten aanzien van beveiliging die tussen Partijen schriftelijk zijn overeengekomen.Leverancier kan niet garanderen dat de informatiebeveiliging onder alle omstandigheden effectief zal zijn.Indien een in de Overeenkomst genoemde beveiligingsmaatregel niet beschikbaar of niet aanwezig is, zullen de beveiligingsmaatregelen voldoen aan een niveau dat, gelet op de stand van de techniek, de gevoeligheid van de gegevens en de aan de beveiliging verbonden kosten, niet onredelijk is. .</p>
    <p>8.2 Klant zal de verstrekte toegangs- en identificatiecodes vertrouwelijk houden en uitsluitend delen met geautoriseerde medewerkers uit de eigen organisatie of met geautoriseerde leveranciers van Klant.Leverancier is bevoegd de hem toegekende toegangscodes, identificatiecodes en certificaten te wijzigen.</p>
    <p>8.3 Leverancier is niet aansprakelijk voor eventueel misbruik van toegangscodes of identificatiecodes en mag ervan uitgaan dat een gebruiker die gebruik maakt van deze toegangscodes of identificatiecodes van een actieve gebruiker ook daadwerkelijk de betreffende actieve gebruiker is.Zodra Cli&euml;nt weet of reden heeft om aan te nemen dat onbevoegden zich toegang hebben verschaft tot identificatiecodes of toegangscodes, zal Cli&euml;nt Leverancier hiervan onverwijld schriftelijk of telefonisch op de hoogte stellen, onverminderd de eigen verplichting van Cli&euml;nt om onverwijld effectieve maatregelen nemen.</p>
    <p>8.4 Klant is verantwoordelijk voor het aanschaffen en onderhouden van hardware en eventuele ondersteunende diensten die nodig zijn voor toegang tot, verbinding met en gebruik van de Dienst.Tevens is Klant verantwoordelijk voor het in stand houden van de beveiliging van de hardware.</p>
    <p>8.5 Leverancier is op geen enkele wijze aansprakelijk voor ondeskundig gebruik van de Dienst door Klant of een Eindgebruiker.Indien door bepaald gedrag van Klant of diens Gebruikers de werking van de Programmatuur in gevaar komt, is Leverancier gerechtigd een Eindgebruiker de toegang tot de Programmatuur tijdelijk of blijvend te ontzeggen of te verhinderen, na een initi&euml;le schriftelijke waarschuwing, waaronder een schriftelijke herinnering.</p>
    <p></p>
    <p><strong>Artikel 9. Intellectuele Eigendomsrechten</strong></p>
    <p>9.1 De intellectuele eigendomsrechten uit hoofde van de Overeenkomst of de aan Klant verleende Dienst blijven berusten bij Leverancier of bij de derde van wie of van wie Leverancier het recht heeft verkregen om deze Programmatuur (of een deel daarvan) aan Klant ter beschikking te stellen. .</p>
    <p>9.2 De (intellectuele) eigendomsrechten op de door Leverancier ingevoerde gegevens berusten bij Klant of bij de rechthebbende(n) van deze gegevens, inclusief de gegevens die gebaseerd zijn op of afgeleid zijn van de gegevens van Klant en die aan Klant zijn verstrekt. de Klant voor het leveren van de SaaS-dienst.</p>
    <p>9.3 Indien Leverancier het aannemelijk acht dat zal komen vast te staan dat de door Leverancier geleverde Programmatuur inbreuk maakt op rechten van derden, is Leverancier naar eigen keuze bevoegd om (I) ervoor te zorgen dat Klant de ter beschikking gestelde Software te blijven gebruiken, of (II) de levering van de betreffende SaaS-dienst op te schorten tegen betaling van een redelijke vergoeding voor het gebruik daarvan en eventuele terugbetaling van het meerdere, of (III) alternatieve Software ter beschikking te stellen die naar het oordeel van Leverancier redelijk oordeel, is gelijk aan de Software die wordt vervangen.Iedere verdere aansprakelijkheid, verplichting tot verdere nakoming of verplichting tot schadevergoeding wordt hierbij uitgesloten.Cli&euml;nt zal Leverancier onverwijld van het bestaan en de aard van dergelijke aanspraken op de hoogte stellen.Cli&euml;nt verplicht zich om Leverancier alle informatie te verstrekken die redelijkerwijs noodzakelijk kan zijn, en indien nodig met Leverancier samen te werken.Indien en voor zover de gestelde inbreuk het gevolg is van wijzigingen in de dienstverlening die door Klant of door een derde namens Klant zijn doorgevoerd, door het gebruik van Software in combinatie met Software die niet door Leverancier is geleverd of als gevolg van gebruik voor een ander doel of op een andere wijze dan waarvoor de Dienst is ontwikkeld of ontworpen, zal Leverancier Klant aansprakelijk stellen voor de gevolgen daarvan.</p>
    <p>9.4 Klant garandeert dat hij, indien en voor zover hem in het kader van de Overeenkomst materialen of gegevens zijn verstrekt, daartoe bevoegd is en dat deze materialen en gegevens geen inbreuk maken op rechten van derden.Klant vrijwaart Leverancier voor eventuele aanspraken van derden die gebaseerd zijn op de bewering dat zodanig beschikbaar stellen, gebruiken, onderhouden, bewerken, installeren of integreren inbreuk maakt op enig recht van die derde.</p>
    <p>9.5 Klant is niet bevoegd eventuele auteursrechtelijke specificaties, merken of andere aanduidingen van de rechthebbende op intellectuele eigendomsrechten uit de geleverde Programmatuur te verwijderen of te wijzigen.Het voorgaande geldt eveneens voor mededelingen waarin staat dat bepaalde informatie een vertrouwelijk karakter heeft.</p>
    <p></p>
    <p>Artikel 10.   <strong>Verplichtingen van partijen</strong></p>
    <p>10.1 Om een goede uitvoering van de Overeenkomst door Leverancier te faciliteren, zal Klant Leverancier steeds tijdig en in redelijkheid de door Leverancier gevraagde gegevens of informatie verstrekken.Leverancier is gehouden de Overeenkomst naar beste inzicht en vermogen uit te voeren.</p>
    <p>10.2 Indien Leverancier bij de uitvoering van de Overeenkomst en/of het verlenen van de Dienst wordt geconfronteerd met feiten of omstandigheden die de voortgang van zijn werkzaamheden mogelijk kunnen belemmeren, zal Leverancier Klant hiervan zo spoedig mogelijk op de hoogte stellen.</p>
    <p>10.3 Klant zal Leverancier tijdig alle gegevens verstrekken die Leverancier nodig heeft voor een goede uitvoering van de Overeenkomst en zal waar nodig medewerking verlenen.</p>
    <p>10.4 Indien de voor de uitvoering van de Overeenkomst of het verlenen van de Dienst benodigde gegevens en informatie niet, niet tijdig of niet overeenkomstig de afspraken aan Leverancier ter beschikking worden gesteld of indien Klant op andere wijze niet aan zijn verplichtingen voldoet heeft Leverancier het recht de uitvoering van de Overeenkomst of de levering van de Dienst op te schorten en de daaruit voortvloeiende kosten in rekening te brengen, met toepassing van zijn gebruikelijke tarieven.</p>
    <p>10.5 Klant is verantwoordelijk voor het gebruik van de Dienst en de wijze waarop gebruik wordt gemaakt van de resultaten van de Dienst.Klant is verantwoordelijk voor het geven van instructies aan en het gebruik door Gebruikers.Klant garandeert dat de Gebruikers over de benodigde kennis en ervaring beschikken om de Dienst te kunnen gebruiken.</p>
    <p>10.6 Leverancier zal opdrachtgever onverwijld op de hoogte stellen indien zich omstandigheden voordoen die leiden tot een wijziging van de opdracht en/of de geschatte inzet en duur.Leverancier is bevoegd in voorkomende gevallen aanvullende facturen te sturen en zal dit aan Cli&euml;nt melden alvorens passende maatregelen te nemen.De opdrachtgever wordt geacht met het verrichten van het meerwerk en de daaraan verbonden kosten en lasten te hebben ingestemd, indien de opdrachtgever zonder schriftelijke bevestiging en binnen een redelijke termijn het meerwerk heeft laten verrichten dat hij niet wenst dat de werkzaamheden worden uitgevoerd. uit te voeren werkzaamheden.</p>
    <p><strong></strong></p>
    <p>Artikel 11.   <strong>Dienstverlening</strong></p>
    <p>11.1 Leverancier zal gedurende de looptijd van de Overeenkomst de Dienst aan Klant leveren.Leverancier zal bij aanvang van de Overeenkomst en op verzoek accountgegevens verstrekken ten behoeve van het gebruik van de Dienst.</p>
    <p>11.2 Klant is bevoegd om Gebruikers binnen zijn organisatie toestemming te geven de Diensten te gebruiken voor interne doeleinden van de organisatie.</p>
    <p>11.3 Tenzij tussen Partijen uitdrukkelijk anders is overeengekomen, is Leverancier niet gehouden tot het verrichten van meer werkzaamheden.Indien Partijen (andere) meer werkzaamheden zijn overeengekomen, gelden daarvoor de voorwaarden die Partijen uitdrukkelijk schriftelijk zijn overeengekomen.</p>
    <p></p>
    <p><strong>Artikel 12. Gebruiksrecht/licentie</strong></p>
    <p>12.1 Het gebruik van de Dienst omvat het niet-exclusieve recht op het reguliere gebruik van de Software, zonder enig recht op verstrekking van een kopie van de Software in welke vorm of formaat dan ook.</p>
    <p>12.2 Het gebruiksrecht (licentie) op de Software is niet overdraagbaar aan derden (onder derden worden mede verstaan de moeder- en zustermaatschappijen en/of dochtermaatschappijen van Klant).</p>
    <p>12.3 Dit recht is beperkt tot het exclusieve gebruik van de Software voor persoonlijk gebruik.Het is Klant niet toegestaan de Software te gebruiken voor commerci&euml;le doeleinden, waaronder: distributie, verkoop, verhuur, vertegenwoordiging, terbeschikkingstelling op commerci&euml;le of onbetaalde basis of enige andere vorm van handel/commercie.</p>
    <p>12.4 Indien er geen andere afspraken zijn gemaakt, is het gebruiksrecht beperkt tot &eacute;&eacute;n (1) gebruiker.</p>
    <p>12.5 Het gebruiksrecht gaat in nadat Klant de betaling heeft verricht en nadat Klant aan zijn overige verplichtingen heeft voldaan.</p>
    <p>12.6 Het gebruiksrecht is beperkt tot de toegang tot en het gebruik van de Software.Er worden geen rechten op de broncode verstrekt.</p>
    <p>12.7 Leverancier faciliteert het gebruik van de Dienst via een eigen hostingdienst.</p>
    <p>12.8 Reverse engineering, decompilatie of enige andere vorm van verandering, modificatie of aanpassing van de door Klant aangeleverde Software in een voor mensen leesbaar formaat is verboden, tenzij uitdrukkelijk toegestaan op grond van dwingend recht of op grond van de Overeenkomst.Daarnaast is het niet toegestaan beveiligingskenmerken te verwijderen of technische beperkingen (dat wil zeggen gebruiksbeperkingen) met betrekking tot de Software te omzeilen.</p>
    <p></p>
    <p><strong>Artikel 13. Garantie en Onderhoud</strong></p>
    <p>13.1 Leverancier zal zich inspannen om de Dienst ononderbroken beschikbaar te houden voor Klant, waarbij onder &lsquo;beschikbaarheid&rsquo; wordt verstaan dat de Dienst daadwerkelijk op de Server wordt geleverd. Onder &lsquo;Beschikbaarheid&rsquo; wordt uitdrukkelijk niet verstaan het bestaan van een operationele point-to- puntverbinding tussen de systemen van Klant en de Server, aangezien Klant geen controle kan uitoefenen over de systemen van Klant en de tussenliggende internetinfrastructuur. </p>
    <p>13.2 De Software waarop de Dienst betrekking heeft, is een generiek product.Leverancier garandeert niet dat de in het kader van de Overeenkomst geleverde Programmatuur vrij van fouten is, ontworpen is voor het beoogde gebruik of anderszins te allen tijde ononderbroken zal functioneren.</p>
    <p>13.3 Leverancier zal zich inspannen om eventuele Bugs in de Programmatuur binnen een redelijke termijn te verhelpen indien en voor zover het de door Leverancier in eigen huis ontwikkelde Programmatuur betreft en Cli&euml;nt de betreffende fouten gedetailleerd schriftelijk heeft beschreven.Klant kan de Bug identificeren en deze Bug kan worden gereproduceerd.Klant zal op alle redelijkerwijze noodzakelijke medewerking verlenen aan het herstel van de dienstverlening.</p>
    <p>13.4 Indien de Bug in kwestie geen verband houdt met de Dienst of de Software maar eerder verband houdt met (I) ondeskundig gebruik, (II)gebruikersfouten of (III) externe oorzaken, zal Leverancier eerder gemaakte kosten met betrekking tot de (incident)onderzoek en onderhoud conform de door Leverancier gehanteerde prijzen en tarieven.</p>
    <p>13.5 Leverancier behoudt zich het recht voor om de Dienst tijdelijk buiten gebruik te stellen ten behoeve van onderhoud, updates en upgrades van de computersystemen van Leverancier.Leverancier zal ervoor zorgen dat deze deactivering zoveel mogelijk buiten kantooruren plaatsvindt en Klant tijdig informeren over de geplande deactivering.Leverancier zal wegens deactivering, zoals hier bedoeld, nimmer enige schadevergoeding van welke aard dan ook aan Klant verschuldigd zijn.</p>
    <p>13.6 Leverancier heeft het recht om de Software zonder voorafgaande toestemming met tussenpozen te wijzigen om de functionaliteit ervan te verbeteren, Bugs te verhelpen en beveiligingsrisico&apos;s aan te pakken.Leverancier zal zich inspannen om eventuele Bugs in de Software te verhelpen, maar kan niet garanderen dat alle Bugs ook daadwerkelijk zullen worden verholpen.Indien een wijziging een wijziging in de functionaliteit tot gevolg heeft, zal Leverancier Klant hierover voorafgaand aan de wijziging informeren.Omdat de Dienst aan meerdere klanten wordt geleverd, is het niet mogelijk om een specifieke aanvraag exclusief voor de Klant te annuleren.</p>
    <p>13.7 Dit artikel is niet van toepassing op Programmatuur die Leverancier van derden aanschaft.Ten aanzien van het gebruik, de beschikbaarheid en het onderhoud van Software van derden kan Leverancier nimmer meer aangesproken worden dan tussen Leverancier en haar verkoper overeengekomen is.</p>
    <p>13.8 Leverancier zal minimaal &eacute;&eacute;n keer per werkdag een back-up maken van de gegevens van Klant. </p>
    <p>13.9 Indien Klant aanvullende diensten wenst af te nemen, zal Leverancier voorafgaand aan de planning en uitvoering van de werkzaamheden een prijsopgave voor de levering van deze diensten ter goedkeuring voorleggen.Tenzij schriftelijk anders overeengekomen, worden de werkzaamheden op basis van nacalculatie in rekening gebracht, inclusief een duidelijke uitsplitsing van de verschillende posten.</p>
    <p>13.10 Het ontwikkelen of leveren van maatwerkoplossingen in aanvulling op of afleiding van de (standaard) Programmatuur (&apos;klantspecifieke versie&apos;) is mogelijk op basis van een door Leverancier uitgebracht voorstel.Het onderhoud van maatwerkprogrammatuur is uitgesloten van regulier onderhoud en kan op basis van een door Leverancier uitgebracht voorstel ook als meerwerk door Leverancier worden aangeboden.</p>
    <p></p>
    <p><strong>Artikel 14. Ondersteuning</strong></p>
    <p>14.1 Leverancier zal assistentie verlenen in de vorm van ondersteuning bij het gebruik van de Dienst (en technische vragen daaromtrent beantwoorden), op de wijze zoals overeengekomen met Klant in de Overeenkomst.Ondersteuning wordt uitsluitend verleend via de vooraf door Klant aangewezen contactpersoon(en).Hoewel Leverancier zich inspant de vragen zo adequaat mogelijk te beantwoorden, kan zij niet instaan voor de juistheid en/of volledigheid van de antwoorden.</p>
    <p>14.2 Klant kan eventuele gebreken of fouten (&ldquo;Bugs&rdquo;) in de Dienst melden op de wijze zoals aangegeven in de Overeenkomst. Het melden en oplossen/fixen van Bugs maakt geen deel uit van de ondersteunende dienstverlening, maar valt onder onderhoud en de daaraan verbonden kosten komen niet ten laste van de ondersteuning waar Klant recht op heeft. </p>
    <p>14.3 Het gebruik en de omvang van de aan Klant verleende ondersteuning is gebonden aan de beperkingen zoals met Klant in de Overeenkomst overeengekomen of bij gebreke daarvan beperkt tot maximaal 8 (acht) uur ondersteuning per maand.</p>
    <p></p>
    <p>Artikel 15.   <strong>Aansprakelijkheid</strong></p>
    <p>15.1 Indien een Partij &eacute;&eacute;n of meer van haar verplichtingen uit de Overeenkomst niet nakomt, zal de andere Partij haar zo gedetailleerd mogelijk schriftelijk in gebreke stellen, waarbij een redelijke termijn wordt gesteld voor herstel van het gebrek. gebrek, met uitzondering van het bepaalde in lid 4 van dit artikel.De ingebrekestelling dient een zo volledig en gedetailleerd mogelijke omschrijving te bevatten, zodat de betreffende partij in de gelegenheid wordt gesteld desgewenst te reageren.</p>
    <p>15.2 De aansprakelijkheid van Leverancier voor eventuele schade van Klant, uit welke hoofde dan ook, is beperkt tot vergoeding van directe schade tot maximaal het bedrag van de in de Overeenkomst bedongen prijs (exclusief BTW).Indien de Overeenkomst een looptijd heeft van meer dan &eacute;&eacute;n jaar, wordt de voor de Overeenkomst bedongen prijs gesteld op het totaalbedrag van de vergoedingen en lasten (exclusief BTW) bedongen voor &eacute;&eacute;n jaar.Voorwaarde voor het ontstaan van enig recht op schadevergoeding is dat opdrachtgever de geconstateerde schade zo spoedig mogelijk na het ontstaan daarvan schriftelijk bij leverancier meldt.Iedere vordering(en) tot schadevergoeding jegens Leverancier vervalt na het verstrijken van vierentwintig (24) maanden na het ontstaan van de vordering, tenzij Klant v&oacute;&oacute;r het verstrijken van deze termijn een rechtsvordering tot vergoeding van de schade heeft ingesteld. Periode van 24 maanden.</p>
    <p>15.3 Onder &lsquo;directe schade&rsquo; wordt uitsluitend verstaan:</p>
    <p>(I) de kosten die Klant heeft gemaakt omdat hij zijn oude systeem of systemen en de daarmee samenhangende faciliteiten langer operationeel moet houden dan nodig is als gevolg van het niet leveren van diensten door de Leverancier op een wettelijk bindende datum, met uitsluiting van eventuele besparingen die voortvloeien uit de vertraagde diensten;</p>
    <p>(II) redelijke kosten, gemaakt om de oorzaak en de omvang van het verlies of de schade vast te stellen, voor zover deze identificatie betrekking heeft op direct verlies of schade in de zin van dit artikel;</p>
    <p>(III) redelijke kosten, gemaakt ter voorkoming of beperking van schade, voor zover de Klant kan aantonen dat deze kosten hebben geleid tot beperking van directe schade in de zin van dit artikel.</p>
    <p>15.4 De Leverancier kan niet aansprakelijk worden gesteld voor indirecte schade of gevolgschade.Voorbeelden van dergelijk verlies of schade zijn onder meer winstderving, gemiste besparingen, verlies van goodwill, slechte prestaties van materialen of software van derden of bedrijfsstagnatie, zelfs als Leverancier op de hoogte is (van de mogelijkheid) dat er schade kan of zal ontstaan.Leverancier is nimmer gehouden tot herstel van beschadigde of verloren gegane gegevens.</p>
    <p>15.5 Met inachtneming van het bepaalde op het gebied van intellectuele eigendomsrechten vrijwaart Klant Leverancier voor eventuele aanspraken van derden uit welke hoofde dan ook ter zake van vergoeding van schade, kosten of rente verband houdende met de Overeenkomst.</p>
    <p>15.6 De in lid 2 tot en met 5 van dit artikel genoemde uitsluitingen en beperkingen van de aansprakelijkheid van Leverancier komen te vervallen indien en voor zover de betreffende schade het gevolg is van opzet of bewuste roekeloosheid van Leverancier of haar medewerkers.</p>
    <p></p>
    <p><strong>Artikel 16. Overmacht</strong></p>
    <p>16.1 Een Partij is niet gehouden tot het nakomen van &eacute;&eacute;n of meer verplichtingen, indien zij daartoe verhinderd is als gevolg van overmacht.</p>
    <p>16.2 Onder &lsquo;overmacht&rsquo; wordt in deze Algemene Voorwaarden &ndash; naast de relevante bepalingen in de wet &ndash; verstaan: alle van buitenaf komende oorzaken, voorzien of onvoorzien, waarover de Leverancier geen controle kan uitoefenen, waardoor hij niet in staat is zijn verplichtingen na te komen. verplichtingen. Hieronder vallen ook stakingen en vakbondsacties van derden.Onder overmacht wordt mede verstaan een toerekenbare tekortkoming van eventuele derden of leveranciers van wie de diensten zijn ingeschakeld, financi&euml;le verplichtingen daaronder niet begrepen.Leverancier heeft ook het recht zich op overmacht te beroepen, indien de omstandigheid die (verdere) nakoming van de Overeenkomst verhindert, intreedt nadat Leverancier zijn verbintenis had moeten nakomen.</p>
    <p>16.3 Indien een Partij wordt getroffen door een overmachtsituatie, zal de betreffende Partij dit zo spoedig mogelijk aan de andere Partij melden.Partijen zullen dan met elkaar in overleg treden om tot een redelijke oplossing te komen.</p>
    <p>16.4 Zodra blijkt dat de overmachtssituatie meer dan zestig (60) dagen zal duren, heeft iedere Partij het recht de Overeenkomst schriftelijk te ontbinden, zonder tot enige schadevergoeding gehouden te zijn.Eventueel v&oacute;&oacute;r dit tijdstip verrichte diensten worden dan naar rato van het opgeleverde werk afgerekend.</p>
    <p></p>
    <p><strong>Artikel 17. Overdracht</strong></p>
    <p>17.1 Leverancier is bevoegd de rechten en verplichtingen uit de Overeenkomst aan derden over te dragen en zal Klant daarvan op de hoogte stellen.Indien de overdracht van verplichtingen aan een derde voor de Klant redelijkerwijs onaanvaardbaar is, heeft hij het recht de Overeenkomst binnen vijf (5) dagen na ontvangst van de daartoe strekkende mededeling te be&euml;indigen.</p>
    <p>17.2 Cli&euml;nt is slechts bevoegd zijn rechten en verplichtingen uit de Overeenkomst over te dragen aan een derde met voorafgaande schriftelijke toestemming van Leverancier.</p>
    <p><strong></strong></p>
    <p><strong>Artikel 18. Overige bepalingen</strong></p>
    <p>18.1 Het is Leverancier toegestaan bij de uitvoering van de Overeenkomst derden in te schakelen.Klant is bevoegd hiertegen bezwaar te maken en/of aanvullende informatie op te vragen bij Leverancier.</p>
    <p>18.2 Eventuele in deze Algemene Voorwaarden opgenomen titels en kopjes dienen slechts ter verduidelijking en zijn niet bedoeld als uitleg of interpretatie van de betreffende bepalingen.</p>
    <p></p>
    <p>Artikel 19.   <strong>Toepasselijk recht en geschillenbeslechting</strong></p>
    <p>19.1 Op deze Algemene Voorwaarden is Nederlands recht van toepassing.</p>
    <p>19.2 Alle geschillen die mochten ontstaan naar aanleiding van deze Algemene Voorwaarden of eventuele nadere overeenkomsten of contracten die daaruit voortvloeiden, zullen uitsluitend worden beslecht door de bevoegde rechter te Rotterdam, Nederland.</p>
  </>
)
